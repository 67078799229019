<template>
  <div id="contact-button">
    <button
      type="button"
      onclick="location.href='https://eestec.ro'"
    >
      <p class="fs-large-1 ">{{ $t("contact.contactButton.text") }}</p>
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
  #contact-button{
    background-color: $blue;
    padding-bottom: 6em;
    padding-top: 4em;
  }
  
  p {
  position: relative;
  font-family: "Hind Siliguri";
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:$white;
  padding-right: 1.5em;
  padding-top: 1em;
  padding-left: 1.5em;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.12em solid $yellow;
    transform: skew(-40deg);
    transition: 0.5s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    width: 110%;
    height: 130%;
    border: 0.12em solid $turquoise;
    transform: skew(-40deg);
    transition: 0.5s ease-in-out;
  }
}

p:hover {
  &::before {
    width: 110%;
    height: 130%;
    border: 0.16em solid $yellow;
  }
  &::after {
    width: 100%;
    height: 100%;
    border: 0.16em solid $turquoise;
  }
}

  .format-button:hover{
    border: solid 0.1em $turquoise;
    outline: solid 0.2em $yellow;
    outline-offset: 0.25em;
  }
  .contact-button:hover{
    outline-offset: 0em;
    cursor: pointer;
  }
  
  @media screen and (min-width: 480px) {
    p{
      padding-top: 0.5em;
    }
  }

</style>