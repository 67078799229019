<template>
  <div id="past-editions">
    <div class="flex-container">
      <h1 class="past-title fs-large-1">
        {{ $t("about.pastEditions.title") }}
      </h1>
      <img class="svg-image" src="../../assets/svg/svg4.svg" loading="lazy" />
    </div>
    <div class="container">
      <div class="svg">
        <div class="cyan-bar"></div>
        <div class="white-triangle"></div>
        <div class="blue-bar"></div>
      </div>
      <div class="square1">
        <h2 class="energy5 fs-medium-4">
          {{ $t("about.pastEditions.energy5.title") }}
        </h2>
        <div class="text-energy">
          {{ $t("about.pastEditions.energy5.text") }}
        </div>
      </div>

      <div class="square2">
        <h2 class="energy5 fs-medium-4">
          {{ $t("about.pastEditions.energy4.title") }}
        </h2>
        <div class="text-energy">
          {{ $t("about.pastEditions.energy4.text") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
//mobile
#past-editions {
  position: relative;
}

.cyan-bar {
  position: absolute;
  background-color: $turquoise;
  height: 2em;
  width: 30em;
  top: -5em;
  right: -19.8em;
  transform: rotate(140deg) scale(1.2);
  @media screen and (max-width: 640px) {
    display: none;
  }
}
.white-triangle {
  position: absolute;
  background-color: $white;
  height: 0em;
  width: 0em;
  border-bottom: 20em solid $white;
  border-left: 20em solid transparent;
  top: -2.2em;
  right: -19.2em;
  transform: rotate(140deg) scale(1.2);
  @media screen and (max-width: 640px) {
    display: none;
  }
}
.blue-bar {
  position: absolute;
  background-color: $blue;
  height: 2em;
  width: 28em;
  top: 18em;
  right: -15em;
  transform: rotate(51.5deg) scale(1.2);
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.flex-container {
  display: flex;
  background-color: $blue;
  height: 100px;
  width: 100vw;
  position: relative;
}

.svg-image {
  width: 100vw;
  height: 10em;
  position: absolute;
}

.past-title {
  position: relative;
  // white-space: nowrap;
  font-size: $font_mobile_large_2;
  font-family: "Hind Siliguri";
  margin-left: 20px;
  z-index: 2;
  top: 1.5em;
  @media screen and (min-width: 640px) {
    margin-left: 100px;
    margin-bottom: 20px;
  }
  color: $white;
  &::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 0.05em;
    background-color: $yellow;
    bottom: 1.6em;
    left: 0;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 3em;
  background-color: $white;
}
.square1 {
  width: 80%;
  margin: auto;
  border-top: 0.5em solid $yellow;
  border-left: 0.5em solid $yellow;
  border-right: 0.5em solid $turquoise;
  border-bottom: 0.5em solid $turquoise;
  padding: 1.3em;
  background-color: $white;
  margin-top: 70px;
}
.square2 {
  width: 80%;
  margin: auto;
  border-top: 0.5em solid $turquoise;
  border-left: 0.5em solid $turquoise;
  border-right: 0.5em solid $yellow;
  border-bottom: 0.5em solid $yellow;
  padding: 1.3em;
  background-color: $white;
}

.energy5 {
  margin-bottom: 0.4em;
  font-weight: 300;
  font-family: "PT Sans Caption";
  font-size: $font-mobile_large_1;
  color: $black;
}
.text-energy {
  font-family: "PT Sans Caption";
  font-size: $font_mobile_small_3;
  color: $black;
}

@media screen and (min-width: 480px) {
  .flex-container {
    height: 120px;
  }

  .past-title {
    padding-top: 50px;
     top: -50px;
     &::after {
       bottom: -0.6em;
     }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .svg {
      display: flex;
    }

    .square1 {
      width: 30%;
      margin: auto;
      border-top: 0.7em solid $yellow;
      border-left: 0.7em solid $yellow;
      border-right: 0.7em solid $turquoise;
      border-bottom: 0.7em solid $turquoise;
      height: 30em;
      padding: 3em;
    }

    .text-energy {
      font-family: "PT Sans Caption";
      color: $black;
      font-size: 21px;
    }
    .square2 {
      width: 30%;
      margin: auto;
      border-top: 0.7em solid $turquoise;
      border-left: 0.7em solid $turquoise;
      border-right: 0.7em solid $yellow;
      border-bottom: 0.7em solid $yellow;
      height: 30em;
      padding: 3em;
    }

    .square1, .square2 {
      width: 760px;
      overflow: hidden;
      margin-top: 100px;
      box-shadow: -5px 5px 20px -10px rgba(0,0,0,0.75);
    }
  }
  .svg-image {
    display: none;
  }
}
</style>
