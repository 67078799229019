<template>
    <div id="contact-page">
        <ContactHero />
        <ContactPersons />
        <ContactButton />
    </div>
</template>

<script>

import ContactHero from"../components/contact-components/ContactHero"
import ContactPersons from"../components/contact-components/ContactPersons"
import ContactButton from"../components/contact-components/ContactButton"

export default {
  components: {
    ContactHero,
    ContactPersons,
    ContactButton
  }
}
</script>