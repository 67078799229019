/* eslint-disable indent */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Register from "../views/Register.vue";
import Trainings from "../views/Trainings.vue";
import DevsPage from "../views/DevsPage.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: { depth: "0" }
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: { depth: "1" }
    },
    {
        path: "/about",
        name: "About",
        component: About,
        meta: { depth: "2" }
    },
    {
        path: "/trainings",
        name: "Trainings",
        component: Trainings,
        meta: { depth: "3" }
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: { depth: "4" }
    },
    {
        path: "/devs",
        name: "Devs",
        component: DevsPage,
        meta: { depth: "5" }
    }
];

const router = new VueRouter({
    routes,
    mode: "history",
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

export default router;