import Vue from 'vue';
import VueResource from 'vue-resource'; // used for http requests
import VueScrollTo from 'vue-scrollto'; // used for making the scrolling motion of the to-top-button smooth
import * as VueGoogleMaps from 'vue2-google-maps'; // used for accesing the google maps api in an element, it is not maintained anymore and only for vue2
// used for changing the language
import Vuei18n from 'vue-i18n';
// used for routing betwen different pages
import router from './router/index';
import App from './App.vue';
import en from './locales/en.json';
import ro from './locales/ro.json';


import store from './store';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;



Vue.use(VueResource);

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAQLikAuA2vwuToJgv5DEWF16i5T1V9ieY', // key registered with google!!
  },
});

Vue.use(Vuei18n);

const i18n = new Vuei18n({
  locale: 'ro',
  messages: {
    en,
    ro,
  },
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render(h) { return h(App); }
}).$mount('#app');
