/* eslint-disable no-param-reassign */
function categorySorter(category1, category2) {
  if (category1.order < category2.order) {
    return -1;
  } if (category1.order > category2.order) {
    return 1;
  }
  return 0;
}
  
export default {
  setPartners: (state, data) => {
    state.partners = data.sort(categorySorter);
    state.loadingPartners = false;
  },
};
  