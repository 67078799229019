<template>
  <div class="container">
    <div class="svg-elice" v-if="differentFooter">
      <img loading="lazy" class="main-img" src="../../assets/svg/grupTurcoaz.svg" alt="grup" />
      <img
        class="elice elice1 rotating rotating1"
        src="../../assets/svg/elice_6.svg"
        alt="elice"
        loading="lazy"
      />
      <img
        class="elice elice2 rotating rotating2"
        src="../../assets/svg/elice_6.svg"
        alt="elice"
      />
      <img loading="lazy"
        class="elice elice3 rotating rotating3"
        src="../../assets/svg/elice_6.svg"
        alt="elice"
      />
    </div>
    <div :class="{ difFoot: differentFooter, 'footer-container': true }" @click="goToDevs()">
      <div class="footer">
        <div class="circle">
          <a
            href="https://www.facebook.com/EESTEC.Bucuresti"
            class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/facebook.svg" alt="facebook icon"
          /></a>
        </div>
        <div class="circle">
          <a
            href="https://www.instagram.com/eestec_lc_bucharest"
            class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/instagram.svg" alt="instagram icon"
          /></a>
        </div>
        <div class="circle">
          <a href="https://www.linkedin.com/company/450726/" class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/linkedin.svg" alt="linkedin icon"
          /></a>
        </div>
        <div class="circle">
          <a
            href="https://www.youtube.com/user/eeStecBucuresti"
            class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/youtube.svg" alt="youtubeicon" />
          </a>
        </div>
      </div>
      <div class="footer-info">
        <div class="footer-label fs-small-4">
          Splaiul Independenței, nr. 313, BN401, UPB
        </div>
        <div class="footer-label fs-small-4">0729 740 116</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nrOfClicks: 0,
      differentFooter: false,
    };
  },
  created() {
    if (this.$router.currentRoute.path === "/trainings") {
      this.differentFooter = true;
    } else {
      this.differentFooter = false;
    }
  },
  watch: {
    $route() {
      if (this.$router.currentRoute.path === "/trainings") {
        this.differentFooter = true;
      } else {
        this.differentFooter = false;
      }
    },
  },
  methods: {
    goToDevs() {
      if (this.nrOfClicks === 2) {
        this.nrOfClicks = 0;
        if (this.$route.name !== "DevPage") {
          this.$router.push("/devs");
        }
      } else {
        this.nrOfClicks += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  background-color: $blue;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  padding-top: 3%;
}

.svg-elice {
  display: none;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}

.circle {
  margin: 1%;
  @media screen and (max-width: 460px) {
    margin: 1% 2% 3% 2%;
  }
}

.footer-icon img {
  min-width: 30px;
  min-height: 30px;
  width: 3vw;
  height: 3vw;
  max-width: 50px;
  max-height: 50px;
}

.footer-info {
  padding-bottom: 1em;
}

.footer-label {
  width: 100%;
  align-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  line-height: 2em;
  font-family: "PT Sans Caption";
  color: $white;
  font-size: $font_mobile_small_2;
  @media screen and (max-width: 460px) {
    font-size: $font_mobile_small_1;
  }
}

@media screen and (min-width: $min_desktop_width) {
  .svg-elice {
    display: block;
    position: relative;
    background-color: $blue;
  }

  .difFoot {
    background-color: $turquoise;
  }

  .footer-icon img {
    width: 4vw;
    height: 4vw;
  }

  .main-img {
    object-fit: cover;
    width: 100%;
    margin-bottom: -5px;
  }

  .elice {
    width: 15vw;
    height: 15vw;
    z-index: 4;
    position: absolute;
  }

  .elice1 {
    top: -7.2vw;
    left: 10.3vw;
  }

  .elice2 {
    top: -6.1vw;
    left: 26vw;
  }

  .elice3 {
    top: -7.2vw;
    left: 46.9vw;
  }

  .circle {
    background-color: $blue;
    border-radius: 50%;
    line-height: 0;
    z-index: 5;
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating1 {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }

  .rotating2 {
    -webkit-animation: rotating 1.8s linear infinite;
    -moz-animation: rotating 1.8s linear infinite;
    -ms-animation: rotating 1.8s linear infinite;
    -o-animation: rotating 1.8s linear infinite;
    animation: rotating 1.8s linear infinite;
  }

  .rotating3 {
    -webkit-animation: rotating 2.2s linear infinite;
    -moz-animation: rotating 2.2s linear infinite;
    -ms-animation: rotating 2.2s linear infinite;
    -o-animation: rotating 2.2s linear infinite;
    animation: rotating 2.2s linear infinite;
  }

  @media screen and (max-width: 1601px) {
    .elice1 {
      top: -7.2vw;
      left: 10.3vw;
    }

    .elice2 {
      top: -6.1vw;
      left: 26vw;
    }

    .elice3 {
      top: -7.2vw;
      left: 46.8vw;
    }
  }

  @media screen and (max-width: 1369px) {
    .elice1 {
      top: -7.2vw;
      left: 10.3vw;
    }

    .elice2 {
      top: -6.1vw;
      left: 25.8vw;
    }

    .elice3 {
      top: -7.2vw;
      left: 46.5vw;
    }
  }
  @media screen and (max-width: 1000px) {
    .elice1 {
      top: -7.2vw;
      left: 10.2vw;
    }

    .elice2 {
      top: -6.1vw;
      left: 25.6vw;
    }

    .elice3 {
      top: -7.2vw;
      left: 46.3vw;
    }
  }

  @media screen and (max-width: 780px) {
    .elice1 {
      top: -7.2vw;
      left: 10.2vw;
    }

    .elice2 {
      top: -6.1vw;
      left: 25.5vw;
    }

    .elice3 {
      top: -7.2vw;
      left: 46vw;
    }
  }

  @media screen and (max-width: 570px) {
    .elice1 {
      top: -7.2vw;
      left: 10.2vw;
    }

    .elice2 {
      top: -6.1vw;
      left: 25.4vw;
    }

    .elice3 {
      top: -7.2vw;
      left: 45.8vw;
    }
  }
}
</style>
