import Vue from 'vue';
import Vuex from 'vuex';
import partnersModule from './modules/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobileMenuIsOpen: false
  },
  modules: {
    partnersModule,
  },
});
