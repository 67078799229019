const devs = {
  templars: [
    {
      name: "Andrei",
      photo: "https://i.imgur.com/CCMAxQa.jpg ",
      quote: `"Facem site-u, nu facem site-u, semințele același gust are."`
    },
    {
      name: "Alex",
      photo: "https://i.imgur.com/hHeVm4x.jpg",
      quote: `"Bad biscuits make the baker broke, bro" - Jake the dog"`
    },
    {
      name: "Andrei",
      photo: "https://i.imgur.com/Jvg0eDm.jpg ",
      quote: `"Enmity is like friendship but different"`
    },
    {
      name: "Cristi",
      photo: "https://i.imgur.com/tx0pEJ8.jpg",
      quote: `"JimmyJungler-EUNE dacă vreți să dăm un lol"`
    },
    {
      name: "Florin",
      photo: "https://i.imgur.com/FnIMnib.jpg",
      quote: `"Am venit pentru bere."`
    },
    {
      name: "Nicoleta",
      photo: "https://i.imgur.com/9usz7dp.jpg",
      quote: `"<DIV>e into my depression✨"`
    },
    {
      name: "Robert",
      photo: "https://i.imgur.com/sASbf9C.png ",
      quote: `"Dick my horse into the sun, brother" - Gandhi`
    },
    {
      name: "Ștefan",
      photo: "https://i.imgur.com/t36AUip.jpg ",
      quote: `"Daca folosesti !important ceri scandal"`
    },
    {
      name: "Vlad",
      photo: "https://i.imgur.com/vxhGfJI.jpg ",
      quote: `"Își pun unii quote-uri pe aicea de zici că-s profilurile lor de Tinder"`
    }
  ],
  architects: [
    {
      name: "Raluca",
      photo: "https://i.imgur.com/LzniQGD.png",
      quote: `"Acolo unde esti se afla lumea intreaga."`
    },
    {
      name: "Cătă",
      photo: "https://i.imgur.com/IldHEYv.jpg",
      quote: `"Everyone you meet is fighting a battle you know nothing about. Be kind. Always."`
    },
    {
      name: "Irina",
      photo: "https://i.imgur.com/1b4iH2p.jpg ",
      quote: `"O femeie șmecheră se descurcă"`
    },
    {
      name: "Mădă",
      photo: "https://i.imgur.com/s1ZxYIe.jpg ",
      quote: `"Timpul vindeca tot. Nicio stare nu rămâne neschimbata de-a lungul timpului."`
    }
  ]
};

export default devs;
