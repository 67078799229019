import Vue from 'vue';
import VueResource from 'vue-resource';
// import partnersjson from '@/assets/resources/partners.js'

Vue.use(VueResource);

// const development = false;

export default {
  requestPartners: (context) => {
    // if(development) {
    // context.commit("setPartners", partnersjson);
    // } else {
    Vue.http.get('https://us-central1-eestec-backend.cloudfunctions.net/app/api/eac6-partners').then(
      (response) => {
        context.commit("setPartners", response.data);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        // context.commit("setPartners", partnersjson);
      },
    );
  },
  // }
};
