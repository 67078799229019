<template>
  <div id="trainers">
    <div class="header">
      <h1 class="fs-large-2">{{ $t("trainings.trainers.header") }}</h1>
      <img class="is-mobile" src="../../assets/svg/svg4.svg" />
      <img class="is-desktop" src="../../assets/svg/trainers-svg-desktop.svg">
    </div>
    <div class="trainers">
      <div
        class="trainer"
        v-for="(trainer, index) in $t('trainings.trainers.trainers')"
        :key="index"
      >
        <div class="photo-container">
          <img loading="lazy" class="photo" :src="trainer.photo" />
        </div>
        <div class="name fs-medium-2">{{ trainer.name }}</div>
        <div :class="{ description: true, 'description-open': descriptionIsFull[index], 'fs-small-2': true }" @click="openDescription(index)">
          {{ trainer.description }}
        </div>
        <div class="btn fs-small-1" @click="openDescription(index)">{{ btnText[index] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      descriptionIsFull: new Array(10).fill(false)
    };
  },
  computed: {
    btnText() {
      return new Array(10).fill(this.$t("trainings.trainers.buttonMessage1"));
    }
  },
  methods: {
    openDescription(index) {
      this.descriptionIsFull.splice(index, 1, !this.descriptionIsFull[index]);
      this.btnText.splice(
        index,
        1,
        this.btnText[index] == this.$t("trainings.trainers.buttonMessage2")
          ? this.$t("trainings.trainers.buttonMessage1")
          : this.$t("trainings.trainers.buttonMessage2")
      );
    }
  }
};
</script>

<style scoped lang="scss">
#trainers {
  position: relative;
  background-color: $white;
}

.header {
  background-color: $white;
}

h1 {
  font-family: "Rockwell";
  font-size: $font_mobile_large_2;
  color: $white;
  display: inline-block;
  position: absolute;
  left: 1em;
  top: 1em;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.1em;
    width: 70%;
    height: 2px;
    background-color: $yellow;
  }
}


h1 + img {
  width: 100%;
}

.trainers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 100px;
  background-color: $white;
}

.trainer {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
}

.photo-container {
  width: 50%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    min-width: 165px;
    top: -5%;
    left: 0;
    box-sizing: border-box;
    border: 4px solid $turquoise;
    transform: skew(-7deg);
    z-index: 2;
  }
}

.photo {
  width: 100%;
  min-width: 165px;
  height: 250px;
  object-fit: cover;
  box-sizing: border-box;
  border: 4px solid $yellow;
  position: relative;
}

.name,
.description,
.btn {
  font-family: "MS-UI-Gothic";
}

.name {
  font-size: $font_mobile_medium_1;
  margin: 2em 3em;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $turquoise;
    bottom: -0.2em;
    left: 0;
  }
  &::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 2px;
    background-color: $turquoise;
    bottom: -0.7em;
    left: 20%;
  }
}

.description {
  font-size: $font_mobile_small_1;
  text-align: center;
  max-height: 4.5ch;
  overflow-y: hidden;
  margin-bottom: 1em;
  transition: max-height 300ms ease;
  &-open {
    max-height: 40ch;
  }
}

.btn {
  font-size: $font_mobile_small_1;
  position: absolute;
  bottom: 0;
  right: 0;
}

.is-desktop {
  display: none;
}

@media screen and (min-width: 480px) {
  .is-mobile {
    display: none;
  }

  .is-desktop {
    display: block;
    width: 100%;
  }

  h1 {
    top: 10vw;
  }

  .trainers {
    width: 90%;
    margin: auto;
    gap: 0;
    align-items: flex-start;
  }

  .trainer {
    width: 330px;
    margin: 0 5%;
    margin-bottom: 200px;
    display: block; 
  }

  .photo-container {
    width: 100%;
  }

  .photo {
    height: 450px;
    min-width: 250px;
  }

  .name {
    margin: 1em 0;
    &::after {
      width: 50%;
      left: 25%;
    }
    &::before {
      width: 30%;
      left: 35%;
      bottom: -0.5em;
    }

  }

  .btn:hover {
    cursor: pointer;
  }
  .description {
    transition: max-height 500ms ease,
                width 300ms ease,
                transform 300ms ease;
    max-height: 100px;
    &-open {
    max-height: 1000px;
    width: 500px;
    transform: translateX(-90px);
  }
}
}
</style>
