<template>
  <div id="trainings-hero">
    <h1 class="fs-large-2">{{$t("trainings.trainingsHero.header")}}</h1>
    <div class="program">
      <div
        class="day-card"
        v-for="(day, index) in $t('trainings.trainingsHero.program')"
        :key="index"
      >
        <p class="day fs-medium-2">
          {{ day.day }}
        </p>
        <p class="date fs-small-2 ">
          {{ day.date }}
        </p>
        <div
          class="training fs-small-2 "
          v-for="(training, index2) in day.trainings"
          :key="index2"
        >
          <p class="time fs-small-2 ">
            {{ training.time }}
          </p>
          <p class="title fs-small-2 ">{{ training.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#trainings-hero {
  padding-bottom: 30px;
  width: 100%;
  background: linear-gradient(90deg, $white 50%, $blue 50%);
  position: relative;
  // height: 100vh;
}
h1 {
  font-family: "Hind Siliguri";
  font-weight: 700;
  padding-top: 110px;
  position: relative;
  color: $blue;
  font-size: $font_mobile_large_2;
  text-align: left;
  margin-left: 1em;
  &::after {
    content: "";
    position: absolute;
    width: 25%;
    height: 0.05em;
    background-color: $purple;
    bottom: -0.1em;
    left: 0%;
  }
}

.program {
  display: grid;
  margin-top: 3em;
  width: 98vw;
  grid-template-columns: 49vw 49vw;
  row-gap: 1.5em;
  position: relative;
  & > * {
    flex-basis: 37%;
    margin: 0 1em;
  }
}

.day-card {
  min-width:140px;
  display: flex;
  font-family: "PT Sans Caption";
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
  align-content: flex-start;
  font-size: $font_mobile_small_3;
  padding: 14px;
  & > * {
    flex-basis: 100%;
  }
   &::before{
    content:"";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.13em solid $yellow;
    bottom: 0;
    right:0;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.13em solid $turquoise;
    bottom: 7px;
    right: 7px;
  }
}
.day-card:nth-child(even) {
  color: $white;
  &::after {
    bottom: 8px;
    right: 8px;
  }
}

.day {
  margin: 0;
  font-size: $font-mobile_medium_2;
}
.date {
  font-size: $font_mobile_small_3;
  font-family: "PT Sans Caption";
  position: relative;
  margin: 1px;
  &::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 0.06em;
    background-color: $purple;
    bottom: -0.1em;
    left: 10%;
  }
}

.time {
  font-size: $font_mobile_small_2;
  padding-top: 4px;
  position: relative;
  margin: 1px;
  &::after {
    content: "";
    position: absolute;
    width: 55%;
    height: 0.07em;
    background-color: $black;
    bottom: -0.1em;
    left: 23%;
  }
}
.day-card:nth-of-type(2),
.day-card:nth-of-type(4) {
  .time {
    &::after {
      background-color: $yellow;
    }
  }
}

.title {
  font-family: "PT Sans Caption" ;
  margin: 1px;
  font-size: $font_mobile_small_2;
  margin-bottom: 14px;
  margin-left: 2px;
}
@media screen and (min-width: 480px){
  #trainings-hero{
  background: $blue;
  padding: 40px;
  padding-bottom: 6em;
  }
  h1 {
  color: $white;
  &::after {
    width: 3em;
    height: 0.01em;
    background-color: $yellow;
  }
}
.program {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4em;
  width: 85vw;
  row-gap: 4em;
  column-gap: 2em;
  position: relative;
   & > * {
    flex-basis: 20%;
    margin: 0 1em;
  }
}
.day-card {
  align-content: flex-start;
  padding: 20px;
  color: $white;
  align-content:center;
  
  // mac-height: 
  & > * {
    flex-basis:100%;
  }
  &::before{
    transition: all 0.3s ease-in-out;
  }
  &::after {
    transition: all 0.3s ease-in-out;
    right: 12px;
    bottom: 12px;
  }
}
.day-card:nth-child(even) {
  color: $white;
  &::after {
    bottom: 12px;
    right: 12px;
  }
}
.day-card:hover{
  &::before{
    border: 0.15em solid $yellow;
    bottom: 12px;
    right: 12px;
  }
  &::after {
    border: 0.15em solid $turquoise;
    bottom: 0px;
    right: 0px;
  }
}

.time {
  &::after {
    background-color: $yellow;
  }
}
.training {
  margin-top: 0.5em;
}
}
</style>
