<template>
  <div id="contact-persons">
    <div class="line-turq1" />
    <div class="line-turq2" />
    <h1 class="byebye" v-html="$t('contact.contactPerson.title') + '<br>'"></h1>
    <h1 class="hi fs-large-1">{{ $t("contact.contactPerson.title3") }}</h1>
    <div class="background">
      <div class="card" v-for="(contact, index) in contacts" :key="index">
        <div class="image-box">
          <img loading="lazy" :src="contact.photo" />
        </div>
        <div class="text-box">
          <h2 class="fs-medium-5">{{ $t(`contact.contactPerson.contacts[${index}].title`) }}</h2>
          <p class="fs-medium-5">{{ $t(`contact.contactPerson.names[${index}].name`) }}</p>
          <div class="data fs-medium-2">
            {{ $t(`contact.contactPerson.phones[${index}].phone`) }}
          </div>
          <div class="data fs-medium-2">
            {{ $t(`contact.contactPerson.emails[${index}].email`) }}
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-svg-container">
      <div class="svg-triangle-cyan"></div>
      <div class="svg-triangle-white"></div>
      <div class="svg-triangle-white-right"></div>
      <div class="svg-triangle-cyan-right"></div>
    </div>
  </div>
</template>

<script>
import contacts from "@/assets/resources/contacts";
export default {
  data() {
    return {
      contacts,
    };
  },
};
</script>

<style scoped lang="scss">
.hi {
  @media (max-width: $min_desktop_width) {
    display: none;
  }
}
.byebye {
  @media (min-width: $min_desktop_width) {
    display: none;
  }
}
.svg1 {
  display: none;
  @media (min-width: $min_desktop_width) {
    display: flex;
    margin-top: 30rem;
    margin-right: 0;
    margin-left: 100%;
    transform: skewX(-13deg);
    scale: 170%;
    z-index: 0;
    position: absolute;
    @media (max-width: 1440px) {
      scale: 130%;
    }
    @media (max-width: 960px) {
      scale: 110%;
    }
  }
}
.svg2 {
  display: none;
  @media (min-width: $min_desktop_width) {
    display: flex;
    margin-top: 80rem;
    margin-right: 100%;
    margin-left: 0%;
    scale: 200%;
    z-index: 0;
    position: absolute;
    @media (max-width: 1440px) {
      scale: 130%;
    }
    @media (max-width: 960px) {
      scale: 110%;
    }
  }
}
#contact-persons {
  padding-top: 10px;
  background-color: $blue;
}

.line-turq1 {
  position: relative;
  margin-left: 50%;
  border-top: 8px solid $turquoise;
  width: 50%;
  @media (min-width: 480px) {
      display: none;
    }
}

.line-turq2 {
  position: relative;
  margin-top: 0.3em;
  margin-left: 55%;
  margin-bottom: 1em;
  border-top: 8px solid $turquoise;
  width: 45%;
  @media (min-width: 480px) {
      display: none;
    }
}

.title-contacts {
  margin-bottom: 0.5em;
}
.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8em;
  flex-basis: 30%;
}

.image-box {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    min-width: 165px;
    top: -5%;
    left: 0;
    box-sizing: border-box;
    border: 7px solid $turquoise;
    transform: skew(-7deg);
    z-index: 2;
  }
}

img {
  width: 20em;
  min-height: 300px;
  height: 100%;
  border: 0.5em solid $yellow;
  object-fit: cover;
}

h1 {
  text-align: left;
  margin-left: 1em;
  font-family: "Hind Siliguri";
  font-weight: 700;
  position: relative;
  font-size: $font_mobile_large_1;
  color: $white;
   &::after {
    content: "";
    position: absolute;
    width: 18%;
    height: 0.04em;
    background-color: $yellow;
    bottom: -0.1em;
    left: 0%;
  }
}

h2 {
  color: $white;
  font-family: "PT Sans Caption";
  font-weight: 500;
  font-size: $font_mobile_medium_1;
  margin-top: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  @media (min-width: $min_desktop_width) {
    font-size: 42px;
    font-weight: 500;
  }
}
.data {
  color: $white;
  font-family: "PT Sans Caption,";
  font-weight: 500;
  font-size: $font_mobile_small_3;
  padding-left: 2em;
  padding-right: 2em;
  @media (min-width: $min_desktop_width) {
    font-size: 21px;
    font-weight: 500;
  }
}
p {
  color: $white;
  font-family: "PT Sans Caption";
  font-weight: 500;
  font-size: $font_mobile_medium_1;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 0.5em;
  @media (min-width: $min_desktop_width) {
    font-size: 42px;
    font-weight: 500;
  }
}

.text-box {
  width: 100%;
  text-align: center;
  @media (min-width: $min_desktop_width) {
    margin-top: 0.2em;
  }
}

.dim {
  width: 140px;
}

.background {
  background-color: $blue;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  overflow: visible;
  padding-bottom: 3em;
}

.desktop-svg-container {
  position: relative;
}
.svg-triangle-white-right {
  width: 0;
  height: 0;
  border-bottom: 20em solid $white;
  border-left: 20em solid transparent;
  position: absolute;
  transform: rotate(135deg);
  margin-top: -40em;
  right: -15em;
}

.svg-triangle-cyan-right {
  width: 10em;
  height: 3em;
  background-color: $turquoise;
  position: absolute;
  transform: rotate(-45deg);
  margin-top: -42em;
  right: -6em;
}

.svg-triangle-white {
  width: 0;
  height: 0;
  border-bottom: 8em solid $white;
  border-left: 5em solid transparent;
  position: absolute;
  transform: rotate(-50deg);
  margin-top: -10em;
  margin-left: -4em;
}

.svg-triangle-cyan {
  width: 0;
  height: 0;
  border-bottom: 13em solid $turquoise;
  border-left: 13em solid transparent;
  position: absolute;
  transform: rotate(-31deg);
  margin-top: -10em;
  margin-left: -9em;
}

@media screen and (max-width: 480px) {
  img {
    width: 10em;
    height: 100%;
    border: 0.25em solid $yellow;
  }

  .image-box {
    position: relative;
    &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    min-width: 165px;
    top: -5%;
    left: 0;
    box-sizing: border-box;
    border: 4px solid $turquoise;
    transform: skew(-7deg);
    z-index: 2;
    }
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3em;
    flex-basis: 30%;
  }

  .svg-triangle-white-right {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
  }

  .svg-triangle-cyan-right {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
    margin-right: -100em;
  }

  .svg-triangle-white {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
  }

  .svg-triangle-cyan {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
  }
}
</style>
