<template>
  <div id="header">
    <img
      :class="{ logo: true, 'logo-blur': $store.state.mobileMenuIsOpen, 'is-mobile': true }"
      src="../../assets/images/logo-EAC6.png"
      @click="changePage('/')"
      alt=""
      loading="lazy"
    />
    <div
      :class="{
        'burger-menu': true,
        'burger-menu-open': $store.state.mobileMenuIsOpen,
        'burger-devs': $router.currentRoute.path == '/devs',
        'is-mobile': true
      }"
      @click="openCloseMenu()"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div
      :class="{
        'mobile-header': true,
        'mobile-header-open': $store.state.mobileMenuIsOpen,
        'is-mobile': true
      }"
    >
      <div class="page" v-for="(page, index) in $router.options.routes.slice(0, 5)" :key="index">
        <div
          :class="{
            'page-name': true,
            'page-name-current': page.path == $router.currentRoute.path
          }"
          @click="changePage(page.path)"
        >
          {{ $t(`global.navBar.pages[${index}]`) }}
        </div>
      </div>
      <div
        :class="{ lang: true, 'lang-open': $store.state.mobileMenuIsOpen }"
        @click="changeLang()"
      >
        <div :class="{ 'is-selected': $i18n.locale == 'en' }">EN</div>
        |
        <div :class="{ 'is-selected': $i18n.locale == 'ro' }">RO</div>
      </div>
    </div>
    <div class="desktop-header">
      <div
        :class="{
          page: true,
          'fs-medium-1': true
        }"
        v-for="(page, index) in $router.options.routes.slice(0, 5)"
        :key="index"
      >
        <div
          :class="{
            'page-name-desktop': true,
            'page-name-desktop-current': page.path == $router.currentRoute.path,
            'page-name-desktop-trainings': page.path == '/' && ($router.currentRoute.path == '/trainings' || $router.currentRoute.path == '/' || $router.currentRoute.path == '/devs')
          }"
          @click="changePageDesktop(page.path)"
        >
          {{ $t(`global.navBar.pages[${index}]`) }}
        </div>
      </div>
      <div class="lang-container fs-small-3" @click="changeLang()">
        <div :class="{ 'is-selected': $i18n.locale == 'ro' }">RO</div>
        <div :class="{ 'is-selected': $i18n.locale == 'en' }">EN</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openCloseMenu() {
      this.$store.state.mobileMenuIsOpen = !this.$store.state.mobileMenuIsOpen;
    },
    changePage(newPath) {
      if (newPath != "/") {
        this.openCloseMenu();
      }
      if (this.$router.currentRoute.path != newPath) {
        this.$router.push(newPath);
      }
    },
    changeLang() {
      this.openCloseMenu();
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "ro";
      } else {
        this.$i18n.locale = "en";
      }
    },
    changePageDesktop(newPath) {
      //for some ungodly reason you need to put something before
      //the if that checks you are not on the page you want to go to
      //otherwise the styling of the page-name wont change
      //what in the everloving fuck
      if (newPath != "/asdasdas") {
        this.openCloseMenu();
      }
      if (this.$router.currentRoute.path != newPath) {
        this.$router.push(newPath);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#header {
  font-family: "PT Sans Caption";
  color: $white;
  overflow: hidden;
  user-select: none;
  z-index: 1000;
}

.logo {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 80px;
  height: 80px;
  object-fit: contain;
  z-index: 1;
  &-blur {
    filter: blur(4px);
  }
}

.burger-menu {
  position: absolute;
  top: 5vh;
  right: 5vh;
  width: 50px;
  height: 32px;
  z-index: 101;
  & > div {
    position: absolute;
    width: 50px;
    height: 5px;
    right: 0;
    background-color: $white;
    border-radius: 2px;
    transition: transform 200ms ease, opacity 200ms ease;
  }
  & > div:first-child {
    top: 0;
  }
  & > div:nth-child(2) {
    top: 13px;
  }
  & > div:nth-child(3) {
    top: 27px;
  }
  &-open {
    & > div:first-child {
      top: 0;
      transform-origin: top left;
      transform: rotateZ(45deg);
    }
    & > div:nth-child(2) {
      top: 13px;
      opacity: 0;
    }
    & > div:nth-child(3) {
      top: 27px;
      transform-origin: center;
      transform: translateY(-10px) translateX(-10px) rotateZ(-227deg);
    }
  }
}

.burger-devs > div {
  background-color: $yellow;
}

.mobile-header {
  position: fixed;
  width: 0;
  height: 100vh;
  right: 0;
  top: 0;
  background-color: $blue;
  z-index: 1;
  -webkit-box-shadow: -5px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px 0px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0px 5px -1px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding-top: 25vh;
  transition: width 300ms ease;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-content: flex-start;
  z-index: 100;
  &-open {
    width: 70vw;
    & div {
      opacity: 1;
    }
  }

  & > div {
    text-align: center;
    flex-basis: 100%;
  }
}

.page {
  font-size: $font_mobile_large_2;
}

.page-name {
  color: $white;
  text-decoration: none;
  opacity: 0;
  transition: opacity 250ms ease;
  position: relative;
  letter-spacing: -3px; //daca arata textul urat modifica asta
  &-current {
    text-decoration: underline;
  }
  &::after {
    content: "";
    position: absolute;
    top: -0.3em;
    bottom: -0.3em;
    left: 0em;
    right: 0em;
  }
}

.lang {
  transition: opacity 300ms ease;
  opacity: 0;
  position: absolute;
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
  font-size: $font_mobile_medium_2;
  align-self: flex-end;
  width: 0;
  & > div {
    width: 0;
    display: inline;
  }
  &-open {
    width: 100%;
    opacity: 1;
    & > div {
      width: 100%;
    }
  }
}

.is-selected {
  color: $turquoise;
}

.desktop-header {
  display: none;
}

@media screen and (min-width: 480px) {
  .is-mobile {
    display: none;
  }
  .desktop-header {
    display: block;
    position: absolute;
    top: 0;
    z-index: 100;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 60px 0 100px;
    padding-top: 20px;
    padding-bottom: 30px;
    box-shadow: 0px 2px 22px 1px rgba(0, 0, 0, 0.4);
  }
  .page {
    color: $white;
    align-self: flex-end;
    margin: 0 30px;
    &:first-of-type {
      margin-right: auto;
      margin-left: 0;
      color: $black;
    }
    &:hover {
      cursor: pointer;
    }
    &:nth-of-type(2) > .page-name-desktop::after {
      background-color: $yellow !important;
    }
    &:not(:first-child) > .page-name-desktop {
      text-shadow: 2px 2px $blue;
    }
  }
  .page-name-desktop {
    transition: transform 200ms ease;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 3%;
      width: 0;
      height: 2px;
      background-color: $turquoise;
      transition: width 300ms ease-in;
    }
    &-current {
      transform: translateY(-10px);
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 3%;
        width: 85% !important;
        height: 2px;
        background-color: $turquoise;
      }
    }
    &-trainings {
      color: $white;
    }

  }
  .lang-container {
    position: relative;
    color: $white;
    display: flex;
    width: 60px;
    flex-wrap: wrap;
    transform: translateY(10px);
    margin-right: 20px;
    &:hover {
      cursor: pointer;
    }
    & > div:first-child {
      align-self: flex-start;
    }
    & > div:nth-child(2) {
      transform: translateX(42px);
    }
    &::after {
      content: "";
      position: absolute;
      left: 22%;
      top: 50%;
      height: 1px;
      width: 90%;
      background-color: $turquoise;
      transform: rotateZ(-42deg);
    }
  }
  .is-selected {
    color: $yellow;
  }
}
</style>
