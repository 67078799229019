const homeHero = {
  images:[
    "https://i.imgur.com/D8dMIPt.png",
    "https://i.imgur.com/J2Ym7oB.png",
    "https://i.imgur.com/7u86xJu.png",
    "https://i.imgur.com/gMqehTJ.png",
    "https://i.imgur.com/hVZmmXs.png",
    "https://i.imgur.com/8GdWK8r.png"
  ]
};

export default homeHero;
