const contacts = [
  {
    photo: "https://imgur.com/zChPvVs.png"
  },
  {
    photo: "https://imgur.com/IOiGh1t.png"
  }
];

export default contacts;
