const organisers = [
  {
    photo: "https://i.imgur.com/9QVFnAw.png"
  },
  {
    photo: "https://i.imgur.com/7HYAE0j.png"
  },
  {
    photo: "https://i.imgur.com/PyaoVf9.png"
  },
  {
    photo: "https://i.imgur.com/BC9oBXw.png"
  },
  {
    photo: "https://i.imgur.com/QXKMdG1.png"
  },
];

export default organisers;
