<template>
  <div id="app">
    <Header />
    <ToTopButton />
    <transition :name="transitionName" :mode="this.$route.meta.mode">
      <router-view :class="{ blur: $store.state.mobileMenuIsOpen, 'page-id': true }" />
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/global-components/Header";
import ToTopButton from "@/components/global-components/ToTopButton";
import Footer from "@/components/global-components/Footer";

export default {
  data() {
    return {
      transitionName: "slideRight"
    };
  },
  components: {
    Header,
    ToTopButton,
    Footer
  },
  watch: {
    $route(to, from) {
      if (to.meta.depth < from.meta.depth) {
        this.transitionName = "slideRight";
      } else {
        this.transitionName = "slideLeft";
      }
    }
  }
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
  scrollbar-color: $yellow $blue; //scroll bar for Firefox
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; //la fel ca la idea lasa aici ca te bat :D
  color: #2c3e50;
  max-width: 100vw;
  min-height: 200vh;
  overflow-x: hidden;
  position: relative;
  // padding-bottom: 100px;
  // @media screen and(min-width: 480px) {
  //   padding-bottom: 10vw;
  // }
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body {
  margin: 0;
}
//scroll bar for Chrome and Safari
body::-webkit-scrollbar {
    width: 1em;
  }
body::-webkit-scrollbar-track{
  background-color: $blue;
}
body::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: $yellow;
}


.blur {
  filter: blur(4px);
  @media screen and (min-width: 480px) {
    filter: none;
  }
}

.page-id {
  min-height: 150vh;
  min-width: 100vw;
  position: relative;
}
//font Desktop
//font small
//font-size: 14px
.fs-small-1 {
  @media screen and (min-width: 480px) {
    font-size: 9px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 19px !important;
  }
}
//font-size:21
.fs-small-2 {
  @media screen and (min-width: 480px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 21px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 28px !important;
  }
}
//font-size:24
.fs-small-3 {
  @media screen and (min-width: 480px) {
    font-size: 16px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 24px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 32px !important;
  }
}
//font-size: 28
.fs-small-4 {
  @media screen and (min-width: 480px) {
    font-size: 19px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 28px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 37px !important;
  }
}
//font medium
//font-size:32
.fs-medium-1 {
  @media screen and (min-width: 480px) {
    font-size: 21px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 43px !important;
  }
}
//font-size:35
.fs-medium-2 {
  @media screen and (min-width: 480px) {
    font-size: 23px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 35px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 47px !important;
  }
}
//font-size:36
.fs-medium-3 {
  @media screen and (min-width: 480px) {
    font-size: 24px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 36px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 48px !important;
  }
}
//font-size:40
.fs-medium-4 {
  @media screen and (min-width: 480px) {
    font-size: 27px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 40px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 53px !important;
  }
}
//font-size:42
.fs-medium-5 {
  @media screen and (min-width: 480px) {
    font-size: 28px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 42px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 56px !important;
  }
}
//font large
//font-size:48
.fs-large-1 {
  @media screen and (min-width: 480px) {
    font-size: 32px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 48px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 64px !important;
  }
}
//font-size:50
.fs-large-2 {
  @media screen and (min-width: 480px) {
    font-size: 33px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 50px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 67px !important;
  }
}
//font-size: 60
.fs-large-3 {
  @media screen and (min-width: 480px) {
    font-size: 40px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 60px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 80px !important;
  }
}
//font-size:64
.fs-large-4 {
  @media screen and (min-width: 480px) {
    font-size: 43px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 64px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 85px !important;
  }
}
//font-size: 72
.fs-large-5 {
  @media screen and (min-width: 480px) {
    font-size: 48px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 72px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 96px !important;
  }
}
// font-size: 228px
.fs-large-6 {
  @media screen and (min-width: 480px) {
    font-size: 200px !important;
  }
  @media screen and (min-width: 1280px) {
    font-size: 228px !important;
  }
  @media screen and (min-width: 1921px) {
    font-size: 342px !important;
  }
}

.slideRight-enter-active,
.slideRight-leave-active {
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  position: fixed;
  width: 100%;
  z-index: 99;
  min-height: 150vh;
  /* e important sa ai width ul setat la 100 pentru ca altfel in trazitie isi modifica
  lungimea sa fie fix cat ocupa elementele si
  unele dintre ele au marimea facuta dupa cea a paginii deci s-ar strica*/
}

.slideRight-enter-active {
  z-index: 2;
}

.slideRight-enter {
  transform: translateX(-100vw);
  min-height: 150vh;
}

.slideRight-enter-to {
  transform: translateX(0);
  min-height: 150vh;
}

.slideRight-leave-to {
  transform: translateX(100vw);
  min-height: 150vh;
}

.slideLeft-enter-active,
.slideLeft-leave-active {
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  position: fixed;
  width: 100%;
  min-height: 150vh;
}

.slideLeft-enter {
  transform: translateX(100vw);
  min-height: 150vh;
}

.slideLeft-enter-to {
  transform: translateX(0);
  min-height: 150vh;
}

.slideLeft-leave-to {
  transform: translateX(-100vw);
  min-height: 150vh;
}

.slideLeft-enter-active + .container,
.slideLeft-leave-active + .container,
.slideRight-enter-active + .container,
.slideRight-leave-active + .container {
  margin-top: 100vh;
}
</style>
